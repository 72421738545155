import React from "react";
import { Link } from "react-router-dom";
import "./MobilePlatformApps.css"
const APKLink = `${process.env.REACT_APP_FRONTEND_URL}/PCX-Mobile.apk`;
const MobilePlatformApps = () => {
  const DownloadFun = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };
  return (
    <>
      <section className="mobile-py-20-120 user-dashboard">
        <div className="user-profile-area">
          <div className="container mb-4">
            <div className="row gutters">
              <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="PlatformApps-btn-area">
                    <div className="PlatformApps-btn">
                      <button className="menu-item"  onClick={() => DownloadFun(APKLink)}>
                        <span
                          style={{
                            padding: "4px",
                            backgroundColor: "indigo",
                            borderRadius: "4px",
                            color: "#FFF",
                          }}
                        >
                          <i className="fas text-white  fa fa-download"></i>
                        </span>
                        App Download-Android
                      </button>
                    </div>
                    <div className="PlatformApps-btn">
                      <button className="menu-item" >
                        <span
                          style={{
                            padding: "4px",
                            backgroundColor: "black",
                            borderRadius: "4px",
                            color: "#FFF",
                          }}
                        >
                          <i className="fas text-white  fa fa-download"></i>
                        </span>
                        App Download-iOS
                        <br />
                        <small>upcoming</small>
                      </button>
                    </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobilePlatformApps;
