import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./logo.png";
import "./MobileUserHeader.css";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import MobileSideBarMenu from "./MobileSideBarMenu/MobileSideBarMenu.js";
import { toast } from "react-toastify";
const MobileUserHeader = () => {
  const { LoginWithEmail, authUser } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const ShowMenuRef = useRef();

  const handleShow = () => {
    if (show) {
      ShowMenuRef.current.style.display = "none";
      setShow(false);
    } else {
      ShowMenuRef.current.style.display = "block";
      setShow(true);
    }
  };

  

  return (
    <>
      <div className="user-mobile-header-section">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="user-mobile-header-logo">
            <div onClick={handleShow}>
                <i className="fa-solid fa-user"></i>
              </div>
            </div>
            <div className="searchBarMain">
              <i className="fa-solid fa-magnifying-glass "></i>
              <input
                type="text"
                name="header-search"
                id="searchBarInput"
                placeholder="Search"
              />
            </div>
            <div className="user-mobile-header-icon">
              <Link to="/user/contact">
                <i className="fa-solid fa-headset"></i>
              </Link>
              <Link to="/user/announcement">
              <i className="fa-solid fa-bell"></i>
              </Link>
             
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }} ref={ShowMenuRef}>
        <MobileSideBarMenu
          handleShow={handleShow}
          authUser={authUser}
        ></MobileSideBarMenu>
      </div>
    </>
  );
};

export default MobileUserHeader;
