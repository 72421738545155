import React, { useState } from "react";
import "./MobileHistoryPage.css";
import DepositHistory from "./DepositHistory/DepositHistory";
import WithdrawHistory from "./WithdrawHistory/WithdrawHistory";
import TradeHistory from "./TradeHistory/TradeHistory";
import BitcoinMiningHistory from "./BitcoinMiningHistory/BitcoinMiningHistory";
import GoldMiningHistory from "./GoldMiningHistory/GoldMiningHistory";
import InvestmentPlanHistory from "./InvestmentPlanHistory/InvestmentPlanHistory";
import LoanHistory from "./LoanHistory/LoanHistory";
import NewCoinHistory from "./NewCoinHistory/NewCoinHistory";

const MobileHistoryPage = () => {
  const [HistoryItem, setHistoryItem] = useState("Deposit");

  return (
    <>
      <section className="mobile-history-page-area">
        <div className="container">
          <div className="mobile-history-page-menu-area">
            <div className="mobile-history-page-menu-item">
              <p
                onClick={() => setHistoryItem("Deposit")}
                className={HistoryItem === "Deposit" ? "active" : ""}
              >
                Deposit
              </p>
              <p
                onClick={() => setHistoryItem("Withdraw")}
                className={HistoryItem === "Withdraw" ? "active" : ""}
              >
                Withdraw
              </p>
              <p
                onClick={() => setHistoryItem("Trade")}
                className={HistoryItem === "Trade" ? "active" : ""}
              >
                Trade
              </p>
              <p
                onClick={() => setHistoryItem("Loan")}
                className={HistoryItem === "Loan" ? "active" : ""}
              >
                Loan
              </p>
            </div>
            <div className="mobile-history-page-menu-item">
              <p
                onClick={() => setHistoryItem("Bitcoin")}
                className={HistoryItem === "Bitcoin" ? "active" : ""}
              >
                Bitcoin Mining
              </p>
              <p
                onClick={() => setHistoryItem("Gold")}
                className={HistoryItem === "Gold" ? "active" : ""}
              >
                Gold Mining
              </p>
              <p
                onClick={() => setHistoryItem("Investmen")}
                className={HistoryItem === "Investmen" ? "active" : ""}
              >
                Investmen plan
              </p>
            </div>
            
            <div className="mobile-history-page-menu-item">
              <p
                onClick={() => setHistoryItem("NewCoin")}
                className={HistoryItem === "NewCoin" ? "active" : ""}
              >
                New Coin
              </p>
          
            </div>
            
          </div>

          <div className="mobile-history-item-area">
            {HistoryItem === "Deposit" && <DepositHistory />}
            {HistoryItem === "Withdraw" && <WithdrawHistory />}
            {HistoryItem === "Trade" && <TradeHistory />}
            {HistoryItem === "Bitcoin" && <BitcoinMiningHistory />}
            {HistoryItem === "Gold" && <GoldMiningHistory />}
            {HistoryItem === "Investmen" && <InvestmentPlanHistory />}
            {HistoryItem === "Loan" && <LoanHistory />}
            {HistoryItem === "NewCoin" && <NewCoinHistory />}
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileHistoryPage;
