import React, { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";

const MobileAssetsExchange = () => {
  const { authUser } = useContext(AuthContext);

  const [fromData, setFromData] = useState([
    { name: "Bitcoin" },
    { name: "Ethereum" },
    { name: "Solana" },
    { name: "Tether" },
    { name: "Gold" },
    { name: "Xrp (Ripple)" },
  ]);
  const [fromFilterData, setFromFilterData] = useState([]);

  const handleExchangeFilter = (event) => {
    const value = event.target.value;
    const filterData = fromData.filter((data) => data.name !== value);
    setFromFilterData(filterData);
  };

  const [errorMessage, setErrorMessage] = useState({});
  const refSubmitDis = useRef();

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    const storeData = {user_id: authUser?._id, ...InpuData}
    setErrorMessage();
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .post(`https://api.prospectstreetx.com/api/user/assets/exchange`, storeData, config)
      .then((data) => {
        event.target.reset();
        toast.success(`${data.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        refSubmitDis.current.removeAttribute("disabled");
        console.log(error?.response?.data)
        if (error?.response?.data?.errors) {
          setErrorMessage(error?.response?.data?.errors);
        } else {
          setErrorMessage(error?.response?.data);
        }
      });
  };

  return (
    <>
      <section className="mobile-py-20-280 mobile-user-total-value mobile-deposit-method">
        <div className="user-profile-area">
          <div className="container my-4">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row gutters">
                      <form onSubmit={handleSubmitData}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h6 className="mb-3 text-primary text-center ">
                            Exchange
                          </h6>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              From
                            </label>
                            <select
                              name="FromData"
                              className="form-select"
                              onChange={handleExchangeFilter}
                            >
                              <option selected disabled>
                                select
                              </option>
                              <option value="Bitcoin">Bitcoin</option>
                              <option value="Ethereum">Ethereum</option>
                              <option value="Solana">Solana</option>
                              <option value="Tether">Tether</option>
                              <option value="Gold">Gold</option>
                              <option value="Xrp (Ripple)">Xrp (Ripple)</option>
                            </select>
                            {errorMessage?.FromData && (
                              <span className="text-danger pt-2">
                                {errorMessage?.FromData?.msg}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              To
                            </label>
                            <select name="ToData" className="form-select">
                              <option selected disabled>
                                select
                              </option>

                              {fromFilterData.map((data) => {
                                return (
                                  <option value={data?.name} key={data?.name}>
                                    {data?.name}
                                  </option>
                                );
                              })}
                            </select>
                            {errorMessage?.ToData && (
                              <span className="text-danger pt-2">
                                {errorMessage?.ToData?.msg}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Amount
                            </label>
                            <input
                              type="text"
                              name="amount"
                              className="form-control"
                              id="exampleFormControlInput2"
                              aria-describedby="emailHelp"
                            />
                            {errorMessage?.amount && (
                              <span className="text-danger ">
                                {errorMessage?.amount?.msg}
                              </span>
                            )}
                           <span className="text-danger pt-2">
                        {errorMessage?.message && errorMessage?.message}
                      </span>
                          </div>
                        </div>

                        <div className="row gutters">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="text-center">
                              <button
                                type="submit"
                                id="submit"
                                className="user-btn1  px-5"
                                ref={refSubmitDis}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAssetsExchange;
