import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { useParams } from "react-router-dom";
import BuyCoinModal from "./Modal/BuyCoinModal";
import SellCoinModal from "./Modal/SellCoinModal";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import HTMLReactParser from "html-react-parser";
const MobileNewCoinCandle = () => {
  const { authUser } = useContext(AuthContext);
  const { id } = useParams();

  const [userData, setUserData] = useState([]);
  const [updateData, setUpdateData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.prospectstreetx.com/api/user/auth/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id, updateData]);

  const [userNewCoinHistory, setUserNewCoinHistory] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.prospectstreetx.com/api/user/new/coin/buy/history/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserNewCoinHistory(data);
        });
    }
  }, [authUser?._id, updateData]);

  const [coinData, setCoinData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/user/new/coin/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCoinData(data.data);
        setLoading(false);
      });
  }, [updateData]);

  const [livePrice, setLivePrice] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [livePrice, coinData]);

  const fetchData = async () => {
    if (coinData?.symbol) {
      fetch(
        `https://api.prospectstreetx.com/api/user/new/coin/price/view/${coinData?.symbol}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setLivePrice(data.data);
          if (data.data) {
            setLivePrice(data?.data[0]);
          }
        });
    }
  };



  //   modal

  const [BuyModalShow, setBuyModalShow] = useState(false);
  const [SellModalShow, setSellModalShow] = useState(false);



  if (loading) {
    return;
  }
  return (
    <>
      <section className=" mobile-trade-section user-dashboard new-coin-details-area">
        <div className="row">
          <div className="col-12">
            <div className="trade-area-posce">
              <div className="charts ">
                <div
                  className="charts__head"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"
                >
                  <div className="d-flex gap-3">
                    <button
                      className="nav__link js-tabs-link active"
                      style={{ color: "lime" }}
                    >
                     Total Coin: {userNewCoinHistory?.total}
                    </button>
                  </div>

                  {livePrice?.close && (
                    <div className="nav">
                      <button
                        className="nav__link js-tabs-link active"
                        style={{ color: "lime" }}
                      >
                        {livePrice?.close}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <p className="btn btn-primary expired_time-css pb-2 mt-3">
                            <FlipClockCountdown
                                to={coinData?.expired_time}
                                className="flip-clock"

                            >
                            </FlipClockCountdown >
                        </p>
              <div className="new-coin-details py-5">
                <img
                  className="new-coin-logo"
                  src={`https://api.prospectstreetx.com/${coinData?.image}`}
                  alt=""
                />
                <div>
                  <div className="supply-coin">
                    <p>Supply Coin :</p>
                    <span>{coinData?.supply_coin}</span>
                  </div>
                </div>
              </div>

              <div className="d-flex gap-3">
                <button
                  type="button"
                  className="btn btn-success  w-100 "
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={() => setBuyModalShow(true)}
                >
                  Subscribe
                </button>

                <button
                  type="button"
                  className="btn btn-danger  w-100"
                  data-bs-toggle="modal"
                  style={{ borderRadius: "30px" }}
                  onClick={() => setSellModalShow(true)}
                >
                  Sell
                </button>
              </div>

              <div className="new-coin-dis">
                <p>  {coinData?.dis ? HTMLReactParser(coinData?.dis) : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BuyCoinModal
        className="mobile-trade-modal-section posce-trade-modal"
        BuyModalShow={BuyModalShow}
        setBuyModalShow={setBuyModalShow}
        livePrice={livePrice}
        coinData={coinData}
        show={BuyModalShow}
        userData={userData}
        setUpdateData={setUpdateData}
        onHide={() => setBuyModalShow(false)}
      />
      <SellCoinModal
        className="mobile-trade-modal-section posce-trade-modal"
        SellModalShow={SellModalShow}
        setSellModalShow={setSellModalShow}
        livePrice={livePrice}
        coinData={coinData}
        show={SellModalShow}
        userData={userData}
        setUpdateData={setUpdateData}
        onHide={() => setSellModalShow(false)}
      />
    </>
  );
};

export default MobileNewCoinCandle;
