import React, { useContext, useEffect, useRef, useState } from "react";
import DashBoardSideBar from "../UserDashBoard/DashBoardSideBar";
import "./BitCoinMining.css";
import { Circles } from "react-loader-spinner";
import SingleItem from "./SingleItem";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import HistorySingleItems from "./HistorySingleItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectOption from "./SelectOption/SelectOption";
const BitCoinMining = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [TotalData, setTotalData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(`https://api.prospectstreetx.com/api/user/mining/bitcoin/view`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setData(data?.data);
  //       setPaginateData(data);
  //       setLoading(false);
  //     });
  // }, []);

  // const handlePage = (event) => {
  //   try {
  //     const pageNumber = event?.selected + 1;
  //     const config = {
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //     };
  //     axios
  //       .get(
  //         `https://api.prospectstreetx.com/api/user/mining/bitcoin/view?page=${pageNumber}`,
  //         config
  //       )
  //       .then((response) => {
  //         setData(response?.data?.data);
  //         setPaginateData(response?.data);
  //       })
  //       .catch((error) => {});
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // calcultor

  useEffect(() => {
    fetch(
      `https://api.prospectstreetx.com/api/user/mining/bitcoin/filter/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setTotalData(data?.totalData);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState("");

  const handlePage = (event) => {
    try {
      if (event?.target?.value) {
        setFilter(event?.target?.value);
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      if (event?.target?.value) {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/mining/bitcoin/filter/view?page=1&filter=${event?.target?.value}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `https://api.prospectstreetx.com/api/user/mining/bitcoin/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [calData, setCalData] = useState([]);
  const [DailyProfit, setDailyProfit] = useState(0.0);
  const [TotalProfit, setTotalProfit] = useState(0.0);
  const [dataValue, setDataValue] = useState({});
  const handleSelect = (e) => {
    if (e.target.value) {
      fetch(
        `https://api.prospectstreetx.com/api/user/mining/view/${e.target.value}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (dataValue?.amount) {
            if (
              parseFloat(dataValue?.amount) < parseFloat(data?.data?.min_amount)
            ) {
              setDailyProfit(`min:$${data?.data?.min_amount}`);
              setTotalProfit(`min:$${data?.data?.min_amount}`);
              return;
            } else if (
              parseFloat(dataValue?.amount) > parseFloat(data?.data?.max_amount)
            ) {
              setDailyProfit(`max:$${data?.data?.max_amount}`);
              setTotalProfit(`max:$${data?.data?.max_amount}`);
              return;
            }
            if (data?.data?.period_type === "Day") {
              if (data?.data?.principal === 1) {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum =
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100
                  ) / parseFloat(data?.data?.period);
                const TotalProfitsum =
                  parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                  parseFloat(dataValue?.amount);
                setDailyProfit(Dailysum);
                setTotalProfit(TotalProfitsum);
              } else {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum =
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100 -
                      parseFloat(dataValue?.amount)
                  ) / parseFloat(data?.data?.period);
                const TotalProfitsum =
                  parseFloat(Dailysum) * parseFloat(data?.data?.period) +
                  parseFloat(dataValue?.amount);
                setDailyProfit(Dailysum.toFixed(2));
                setTotalProfit(TotalProfitsum.toFixed(2));
              }
            } else if (data?.data?.period_type === "Months") {
              if (data?.data?.principal === 1) {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum = parseFloat(
                  parseFloat(
                    parseFloat(
                      (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                        100
                    ) / parseFloat(parseFloat(data?.data?.period) * 30)
                  )
                );
                const TotalProfitsum = parseFloat(
                  parseFloat(Dailysum) *
                    parseFloat(parseFloat(data?.data?.period) * 30) +
                    parseFloat(dataValue?.amount)
                );
                setDailyProfit(Dailysum);
                setTotalProfit(TotalProfitsum);
              } else {
                const percentage = parseFloat(data?.data?.percentage);
                const Dailysum = parseFloat(
                  parseFloat(
                    (parseFloat(dataValue?.amount) * parseFloat(percentage)) /
                      100 -
                      parseFloat(dataValue?.amount)
                  ) / parseFloat(parseFloat(data?.data?.period) * 30)
                );
                const TotalProfitsum = parseFloat(
                  parseFloat(Dailysum) *
                    parseFloat(parseFloat(data?.data?.period) * 30) +
                    parseFloat(dataValue?.amount)
                );
                setDailyProfit(Dailysum.toFixed(2));
                setTotalProfit(TotalProfitsum.toFixed(2));
              }
            }

            setCalData(data?.data);
          } else {
            setCalData(data?.data);
          }
        });
    }
  };

  const handleInputBlur = (event) => {
    if (calData?._id) {
      const value = event.target.value;
      const field = event.target.name;
      if (parseFloat(value) < parseFloat(calData?.min_amount)) {
        setDailyProfit(`min:$${calData?.min_amount}`);
        setTotalProfit(`min:$${calData?.min_amount}`);
      } else if (parseFloat(value) > parseFloat(calData?.max_amount)) {
        setDailyProfit(`max:$${calData?.max_amount}`);
        setTotalProfit(`max:$${calData?.max_amount}`);
      } else if (calData?.period_type === "Day") {
        if (calData?.principal === 1) {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat((parseFloat(value) * parseFloat(percentage)) / 100) /
            parseFloat(calData?.period);
          const TotalProfitsum =
            parseFloat(Dailysum) * parseFloat(calData?.period) +
            parseFloat(value);
          setDailyProfit(Dailysum);
          setTotalProfit(TotalProfitsum);
        } else {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat(
              (parseFloat(value) * parseFloat(percentage)) / 100 -
                parseFloat(value)
            ) / parseFloat(calData?.period);
          const TotalProfitsum =
            parseFloat(Dailysum) * parseFloat(calData?.period) +
            parseFloat(value);
          setDailyProfit(Dailysum.toFixed(2));
          setTotalProfit(TotalProfitsum.toFixed(2));
        }
      } else if (calData?.period_type === "Months") {
        if (calData?.principal === 1) {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat((parseFloat(value) * parseFloat(percentage)) / 100) /
            parseFloat(parseFloat(calData?.period) * 30);
          const TotalProfitsum =
            parseFloat(Dailysum) *
              parseFloat(parseFloat(calData?.period) * 30) +
            parseFloat(value);
          setDailyProfit(Dailysum);
          setTotalProfit(TotalProfitsum);
        } else {
          const percentage = parseFloat(calData?.percentage);
          const Dailysum =
            parseFloat(
              (parseFloat(value) * parseFloat(percentage)) / 100 -
                parseFloat(value)
            ) / parseFloat(parseFloat(calData?.period) * 30);
          const TotalProfitsum =
            parseFloat(Dailysum) *
              parseFloat(parseFloat(calData?.period) * 30) +
            parseFloat(value);
          setDailyProfit(Dailysum.toFixed(2));
          setTotalProfit(TotalProfitsum.toFixed(2));
        }
      }
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    } else {
      const value = event.target.value;
      const field = event.target.name;
      const newUser = { ...dataValue };
      newUser[field] = value;
      setDataValue(newUser);
    }
  };

  // Handle submit form

  const refSubmitDis = useRef();
  const [miningInputData, setMiningInputData] = useState([]);
  const [findMiningId, setFindMiningId] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `https://api.prospectstreetx.com/api/user/mining/store/${findMiningId}`,
          { ...miningInputData, user_id: authUser?._id },
          config
        )
        .then((data) => {
          setUpdateData(data);
          toast.success(`${data.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
          event.target.reset();
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data);
          toast.error(`${error?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMiningInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...miningInputData };
    newUser[field] = value;
    setMiningInputData(newUser);
  };

  const [miningHistory, setMiningHistory] = useState([]);
  const [miningHistoryPaginateData, setMiningHistoryPaginateData] = useState(
    []
  );

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/mining/bitcoin/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMiningHistory(data.data);
          setMiningHistoryPaginateData(data);
          setLoading(false);
        });
    }
  }, [authUser?._id, updateData]);

  const handleMiningHistoryPage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.prospectstreetx.com/api/user/mining/bitcoin/history/view/${authUser?._id}?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setMiningHistory(response?.data?.data);
          setMiningHistoryPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <>
        <div className="loader-section d-flex justify-content-center align-items-center">
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="user-dashboard">
          <div className="row">
            <div className="col-3 user-menu-left">
              <DashBoardSideBar></DashBoardSideBar>
            </div>
            <div className="col-9">
              <section className="user-deposit">
                <div className="container-custom">
                  <div className="pb-4">
                    <div className="user-deposit-title pb-3">
                      <h1>Bitcoin Mining</h1>
                      <div className="order-filter-area">
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-filter" /> Filter
                  </p>

                  <div className="form-group">
                    <select className="form-select" onChange={handlePage}>
                      <option selected value="createdAt">
                        Newest
                      </option>
                      <option value="-createdAt">Older </option>
                    </select>
                  </div>
                </div>
                    </div>
                   
                    <div className="row pb-5">
                      <div class="mining-area">
                        <div class="row gy-5">
                          {data?.length !== 0 ? (
                            data.map((data, index) => {
                              if (data) {
                                return (
                                  <SingleItem
                                    data={data}
                                    index={index}
                                    key={data._id}
                                    handleSubmitData={handleSubmitData}
                                    handleMiningInputBlur={
                                      handleMiningInputBlur
                                    }
                                    setFindMiningId={setFindMiningId}
                                    refSubmitDis={refSubmitDis}
                                  ></SingleItem>
                                );
                              }
                            })
                          ) : (
                            <>
                              <div className="text-center h-50">
                                <h6> Data not found</h6>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="pt-3">
                          {/* paginateLinks */}
                          <CustomPagination
                            data={paginateData}
                            handlePage={handlePage}
                          ></CustomPagination>
                          {/* paginateLinks */}
                          {/* <!-- /////////// ///////////history  --> */}
                        </div>

                        <div className="row pb-5">
                          <div className="calci_bg">
                            <div className="container">
                              <div className="row align-items-center">
                                <div className="col-lg-4">
                                  <ul className="calci">
                                    <li>
                                      <div className="cal_text">
                                        <h3>
                                          Select Your Plan
                                          <select
                                            value={calData?._id}
                                            onChange={handleSelect}
                                            id="Ultra"
                                          >
                                            <option selected>Select Now</option>
                                            {TotalData?.length !== 0 &&
                                        TotalData.map((data, idx) => {
                                          if (TotalData) {
                                            return (
                                              <SelectOption
                                                data={data}
                                                key={data?._id}
                                              />
                                            );
                                          }
                                        })}
                                          </select>
                                        </h3>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cal_text">
                                        <h3>
                                          Enter Your Amount
                                          <input
                                            type="number"
                                            className="inpts"
                                            name="amount"
                                            onChange={handleInputBlur}
                                            id="money"
                                          />
                                        </h3>
                                      </div>
                                    </li>
                                  </ul>
                                </div>

                                <div className="col-lg-4">
                                  <ul className="calci1">
                                    <li>
                                      <div className="cal_text">
                                        <h3>
                                          Daily Profit
                                          <span id="profitDaily">
                                            {typeof DailyProfit !== "string"
                                              ? parseFloat(DailyProfit).toFixed(
                                                  2
                                                )
                                              : DailyProfit}
                                          </span>
                                        </h3>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="cal_text">
                                        <h3>
                                          Total Profit
                                          <span
                                            className="cl1"
                                            id="profitTotal"
                                          >
                                            {TotalProfit}
                                          </span>
                                        </h3>
                                      </div>
                                    </li>
                                  </ul>
                                </div>

                                <div className="col-lg-4">
                                  <div className="text_but text-center">
                                    <button
                                      type="submit"
                                      className="but1 hvr-shutter-in-horizontal"
                                    >
                                      Calculate Now{" "}
                                      <i className="ri-play-fill"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-xxl-12 col-md-12 pt-5">
                          <div class=" text-white card ">
                            <div class="card-header d-flex flex-wrap justify-content-between">
                              <h4 class="title text-primary">My Investemt</h4>
                            </div>
                            <div class="card-body  text-white p-2">
                              <div class="text-white table-responsive ">
                                <table
                                  style={{ background: "#1A233A !important" }}
                                  class="text-white table table-responsive-md "
                                >
                                  <thead>
                                    <tr
                                      style={{ color: "#bcd0f7 !important" }}
                                      class=" mx-auto table-header"
                                    >
                                      <th>Invest Date</th>
                                      <th>Invest Amount</th>
                                      <th>Total Return</th>
                                      <th>C.Profit</th>
                                      <th>Remaining</th>
                                      <th>End At</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {miningHistory?.length !== 0 ? (
                                      miningHistory.map((data, index) => {
                                        if (data) {
                                          return (
                                            <HistorySingleItems
                                              data={data}
                                              index={index}
                                              key={data?._id}
                                              paginateData={
                                                miningHistoryPaginateData
                                              }
                                            ></HistorySingleItems>
                                          );
                                        }
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          className="text-muted text-center"
                                          colSpan="100%"
                                        >
                                          No records available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {/* paginateLinks */}
                              <CustomPagination
                                data={miningHistoryPaginateData}
                                handlePage={handleMiningHistoryPage}
                              ></CustomPagination>
                              {/* paginateLinks */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default BitCoinMining;
