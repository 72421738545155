import React from "react";
import "./CompanyApp.css";
const APKLink = `${process.env.REACT_APP_FRONTEND_URL}/PCX-Mobile.apk`;

const CompanyApp = () => {
  const DownloadFun = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };

  return (
    <>
      <div className="company-app-section">
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content">
                  <h2 className="title">App Download</h2>
                  <p className="desc">
                    Download your desired apps for better performance
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-button bg-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="list-button">
                  <button
                    className="btn-action style-2"
                    onClick={() => DownloadFun(APKLink)}
                  >
                    <span>Android</span>
                  </button>
                  <br />
                  <button className="btn-action style-2">
                    <span>iOS </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CompanyApp;
