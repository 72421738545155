import React, { useEffect, useState } from "react";

const SelectOption = ({ data }) => {
  const [Miningdata, setMiningdata] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data?.mining_id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/mining/view/${data?.mining_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMiningdata(data?.data);
          setLoading(false);
        });
    }
  }, [data?.mining_id]);

  if (loading) {
    return;
  }
  return (
    <>
      <option key={Miningdata?._id} value={Miningdata?._id}>
        {Miningdata.promotion_name}
      </option>
    </>
  );
};

export default SelectOption;
