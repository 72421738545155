import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const SingleItem = ({ data,  handleClose }) => {
  const [SingleData, setSingleData] = useState([]);
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.forex_id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/trade/market/forex/view/${data?.forex_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data.data);
          if (data.data) {
            const Symbol = `${data?.data?.base_currency}${data?.data?.quote_currency}`;
            fetchData(Symbol);
          }
        });
    }
  }, [data?.forex_id]);

  const fetchData = async (Symbol) => {
    if (Symbol) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=0y7YImal45qEwEJq9x_n`
      );
      const apiResponse = await data.json();
      if (apiResponse?.quotes) {
        setLivePrice(apiResponse?.quotes[0]);
        setLoading(false);
      }
    }
  };
  if (loading) {
    return;
  }
  return (
    <>
      <li>
        <NavLink 
        onClick={handleClose}
          className="menu-item "
          to={`/user/trade/forex/${SingleData?.base_currency}${SingleData?.quote_currency}/${SingleData?._id}`}
        >
          <div className="d-flex justify-content-between gap-1">
            <div className="text-xl">
              <span>{SingleData?.name}</span>
              <span className="opacity-50 text-base font-light">
                <br />
                ${SingleData?.base_currency}${SingleData?.quote_currency}
              </span>
            </div>
            <div className="d-flex justify-content-between mt-1">
              <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                {livePrice?.bid}$
              </div>
            </div>
          </div>
        </NavLink>
      </li>
    </>
  );
};

export default SingleItem;
