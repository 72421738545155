import React, { useState } from "react";
import SingleItem from "./SingleItem";
import "./MobileCryptoChain.css";
const MobileCryptoChain = () => {
  const [data, setData] = useState([
    { name: "Bitcoin", symbol: "BTC", currency: "USD", market:"crypto"},
    { name: "Ethereum", symbol: "ETH", currency: "USD", market:"crypto" },
    { name: "Solana", symbol: "SOL", currency: "USD", market:"crypto" },
    { name: "Gold", symbol: "XAU", currency: "USD", market:"forex" },
    { name: "Dogecoin", symbol: "DOGE", currency: "USD", market:"crypto" },
    { name: "Theter", symbol: "USDT", currency: "USD", market:"crypto" },
    { name: "Ripple", symbol: "XRP", currency: "USD", market:"crypto" },
    { name: "Cardano", symbol: "ADA", currency: "USD", market:"crypto" },
    { name: "Polygon", symbol: "POL", currency: "USD", market:"crypto" },
  ]);

  return (
    <>
      <section className="popular-market-section">
        <div className="container">
          <div className="row popular-market-title">
            <div className="col-6">
              <p>Name</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-6 text-center">
                  <p>Last Price</p>
                </div>
                <div className="col-6 text-end">
                  <p>24h Chg%</p>
                </div>
              </div>
            </div>
          </div>
          {data.length !== 0 ? (
            data.map((data, index) => {
              if (data) {
                return (
                  <SingleItem
                    data={data}
                    index={index}
                    key={data._id}
                  ></SingleItem>
                );
              }
            })
          ) : (
            <>
              <div className="col">
                <div className="text-muted text-center">Data not found</div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default MobileCryptoChain;
