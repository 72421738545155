import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const MobileStocks = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(
      `https://api.prospectstreetx.com/api/user/trade/market/stocks/mobile/filter/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return;
  }

  return (
    <>
      <section className="mobile-user-trade-section">
        <div className="d-lg-none">
          <div className="bg-all mobile-view d-block d-lg-none">
            <div className="tab-content">
              <div id="a1" className="containerTab">
                <div
                  className="d-flex pb-2 coin_style_div gap-2 flex-column my-3"
                  id="coinContainer"
                >
                  <h6
                    style={{ backgroundColor: "orange" }}
                    className="text-center coin_style_h6 py-2"
                  >
                    Stock
                  </h6>
                </div>
              </div>

              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active">
                  <ul className="coin_menu mt-2">
                    {data.map((data, index) => (
                      <SingleItem
                        data={data}
                        key={data?._id}
                        index={index}
                      ></SingleItem>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileStocks;
