import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SingleItem = ({ data }) => {
  const [SingleData, setSingleData] = useState([]);
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.forex_id) {
      fetch(
        `https://api.prospectstreetx.com/api/user/trade/market/forex/view/${data?.forex_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data.data);
          if (data.data) {
            const Symbol = `${data?.data?.base_currency}${data?.data?.quote_currency}`;
            fetchData(Symbol);
          }
        });
    }
  }, [data?.forex_id]);

  const fetchData = async (Symbol) => {
    if (Symbol) {
      const data = await fetch(
        `https://marketdata.tradermade.com/api/v1/live?currency=${Symbol}&api_key=0y7YImal45qEwEJq9x_n`
      );
      const apiResponse = await data.json();
      if (apiResponse?.quotes) {
        setLivePrice(apiResponse?.quotes[0]);
        setLoading(false);
      }
    }
  };
  if (loading) {
    return;
  }
  return (
    <>
      <div className="bg-white  hot-coin">
        <Link
          to={`/user/trade/forex/${SingleData?.base_currency}${SingleData?.quote_currency}/${SingleData?._id}`}
        >
          <div className="wrapper">
            <div className="d-flex align-items-center gap-2">
              <div className="mr-2">
                <LazyLoadImage
                  effect="blur"
                  alt="img"
                  src={`https://api.prospectstreetx.com/${SingleData?.image}`}
                  className="hot-coin-img"
                />
              </div>
              <div className="flex-grow-1">
                <div className="text-dark font-light">
                  <span className="font-medium text-lg">
                    {SingleData?.name}
                  </span>
                  {/* <span className="text-base">/{data?.currency}</span> */}
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="exchange">
          <div className="text-base font-light text-up">{livePrice?.bid}$</div>
        </div>
      </div>
    </>
  );
};

export default SingleItem;
