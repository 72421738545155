import React from "react";
import apple from "./apple.png";
import playstore from "./playstore.png";
import web from "./web.svg";
import img from "./img.png";
import { Link } from "react-router-dom";
const APKLink = `${process.env.REACT_APP_FRONTEND_URL}/PCX-Mobile.apk`;
const PlatformApps = () => {
  const DownloadFun = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };
 
  return (
    <>
      <section className="platform-apps-page-section">
        <section className="ultimate-platform-section ">
          <div className="et_pb_row et_pb_row_9 ">
            <div className="py-5">
              <div
                data-test-id="main-platform"
                className="css-1c4craf ew5azoy0"
              >
                <div className="UltimatePlatform">
                  <div className="row UltimatePlatform-row ">
                    <div className="col-4">
                      <div className="css-1bil06z ew5azoy4">
                        <h3 className="css-1wh3shw ew5azoy5">
                          <span className="css-12kwjz e193smzc0">
                            <span dir="auto">Enjoy the ultimate platform</span>
                          </span>
                        </h3>
                        <div className="ew5azoy6 css-1ktfw2v e1kdp8s70 py-3">
                          <span dir="auto">
                            A multichart layout, technical analysis, historical
                            quotes and beyond. Everything you're looking for in
                            a platform — on the device of your choice.
                            <br />
                            <br />
                            Sign up using your mail address, verify KYC. Explore
                            the platform and start your trading journey.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                      <picture className="css-2cp6gk ew5azoy7 ultimate-bannar-img">
                        <img src={img} alt="img" />
                      </picture>
                    </div>
                    <div className="col-3">
                      <div className="css-1jk6h72 ew5azoy1 pt-3 app-button">
                        <button
                          className="Button Button_orangeOutline ew5azoy3 css-1ul5z79 e5tlu6e5 download-app"
                          data-test-id="download-win"
                          rel="noopener noreferrer"
                          onClick={() => DownloadFun(APKLink)}
                        >
                          <div className="d-flex  align-items-center">
                            <div className="css-1td69o e5tlu6e1">
                              <img
                                src={playstore}
                                className="css-whh5e5 e5tlu6e2"
                                alt=""
                              />
                            </div>
                            <span className="css-0 e5tlu6e6 ">
                              <span className="css-1ktx9jg e5tlu6e7">
                                <span dir="auto"> Android </span>
                              </span>
                              <span className="css-1bj41xz e5tlu6e4">
                                <span dir="auto">.apk</span> 9.67 Mb
                              </span>
                            </span>
                          </div>
                        </button>
                        <a
                          className="Button Button_orangeOutline ew5azoy3 css-1ul5z79 e5tlu6e5 download-app"
                          data-test-id="download-win"
                          href="#"
                          rel="noopener noreferrer"
                        >
                          <div className="d-flex  align-items-center">
                            <div className="css-1td69o e5tlu6e1">
                              <img
                                src={apple}
                                className="css-whh5e5 e5tlu6e2"
                                alt=""
                              />
                            </div>
                            <span className="css-0 e5tlu6e6 ">
                              <span className="css-1ktx9jg e5tlu6e7">
                                <span dir="auto"> iPhone </span>
                              </span>
                              <span className="css-1bj41xz e5tlu6e4">
                                {/* <span dir="auto">.ios</span> 26.9 Mb */}
                                <span dir="auto">upcoming</span> 
                              </span>
                            </span>
                          </div>
                        </a>
                        <Link
                          className="Button Button_orangeOutline ew5azoy3 css-1ul5z79 e5tlu6e5 download-app"
                          data-test-id="download-win"
                          to="/login"
                        >
                          <div className="d-flex  align-items-center">
                            <div className="css-1td69o e5tlu6e1">
                              <img
                                src={web}
                                className="css-whh5e5 e5tlu6e2"
                                alt=""
                              />
                            </div>
                            <span className="css-0 e5tlu6e6 ">
                              <span className="css-1ktx9jg e5tlu6e7">
                                <span dir="auto">Web Version</span>
                              </span>
                              <span className="css-1bj41xz e5tlu6e4">
                                <span dir="auto">Web Platform</span>
                              </span>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default PlatformApps;
